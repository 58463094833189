<template>
  <div class="doc-form-block" v-loading="loading">
    <div class="doc-form" v-show="step === 'base'">
      <div class="left">
        <fm-title title-text="基础信息"></fm-title>
        <fm-form style="margin:1rem;" label-alone label-align="left">
          <fm-form-item label="收文日期">
            <fm-date-picker
              absolute
              v-model="docData.getTime"
              :clearable="true"
              placeholder="请选择"
              type="date">
            </fm-date-picker>
          </fm-form-item>
          <fm-form-item label="文件名称">
            <fm-input-new v-model="docData.title" type="textarea" placeholder="文件名称"/>
          </fm-form-item>
        </fm-form>
      </div>
      <div class="left">
        <fm-title title-text="文件"></fm-title>
        <file-manage
          preview
          @uploadIng="uploadIng"
          :file-data-ids="fileIds"
          @addFile="addFile"
          @delFile="delFile"
          :funs="{get: true, upload: true, del: true}">
        </file-manage>
      </div>
    </div>
    <div class="fm-footer">
      <fm-btn style="margin-right: 5rem;" @click="save">确定</fm-btn>
      <fm-btn @click="$emit('close')">取消</fm-btn>
    </div>
  </div>
</template>

<script>
import FileManage from '@/components/base/FileManage'

import {
  officialDocumentRequest
} from '../../../api'

export default {
  components: {
    FileManage
  },
  props: {
    workerUserList: Array,
    id: Number,
    type: {
      type: String,
      defualt: 'qtwj'
    }
  },
  data () {
    return {
      loading: false,
      docData: {},
      step: 'base',
      fileIds: [],
      stepList: [],
      newStepList: null
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      if (this.id) {
        let datas = await officialDocumentRequest.getDetail({
          officialDocumentId: this.id
        })
        if (datas.length > 0) {
          this.docData = datas[0]
          this.fileIds = this.docData.fileIds ? this.docData.fileIds.split(',').filter(v => v).map(v => Number(v)) : []
          this.stepList = this.docData.steps || []
        }
      }
    },
    setpChange (data) {
      this.newStepList = data
    },
    uploadIng (uploadIng) {
      this.loading = uploadIng
    },
    addFile (data) {
      this.fileIds.push(data.id)
    },
    delFile (data) {
      this.fileIds = this.fileIds.filter(v => Number(v) !== data.id)
    },
    async save () {
      if (this.loading) {
        return
      }
      this.loading = true
      let parm = Object.assign({}, this.docData, {
        fileIds: this.fileIds.length > 0 ? this.fileIds.join(',') : null,
        sendTime: this.docData.sendTime && this.docData.sendTime instanceof Date ? this.$datetime.format(this.docData.sendTime, 'Y-M-D') : this.docData.sendTime,
        getTime: this.docData.getTime && this.docData.getTime instanceof Date ? this.$datetime.format(this.docData.getTime, 'Y-M-D') : this.docData.getTime,
        stepList: this.newStepList,
        type: this.type
      })
      await officialDocumentRequest.add(parm)
      this.$emit('dataUpdate')
      this.$emit('close')
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.doc-form {
  display: flex;
}
.left {
  flex: 1;
  margin-right: 2rem;
}
.right {
  flex: 1;
}
</style>